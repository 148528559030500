/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

export const pageQuery = graphql`
  query BooksQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 430)
          }
        }
      }
    }
  }
`
const BooksPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""

  return (
    <Layout className="page">
      <Seo title={frontmatter.title} description={excerpt} />
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1 className="title">{frontmatter.title}</h1>
          <article dangerouslySetInnerHTML={{ __html: html }} />
          <p>Please enjoy a free sample from the book (pages 1-19):</p>
          <a href="/assets/Agenda_Movies-free_version.pdf" 
            className="button book-action-button" 
            target="_blank"
            sx={{
              variant: "variants.button",
            }}
            >Read Sample
          </a>

          <p>Order Agenda Movies from your local Amazon:</p>
          <div className="flexbox-style">
            <a href="http://www.amazon.co.uk/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon UK
            </a>
            <a href="http://www.amazon.com/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon US
            </a>
            <a href="http://www.amazon.ca/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon CA
            </a>
            <a href="http://www.amazon.com.au/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon AU
            </a>
            <a href="http://www.amazon.fr/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon FR
            </a>
            <a href="http://www.amazon.es/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon ES
            </a>
            <a href="http://www.amazon.it/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon IT
            </a>
            <a href="http://www.amazon.nl/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon NL
            </a>
            <a href="http://www.amazon.de/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon DE
            </a>
            <a href="http://www.amazon.de/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon FI
            </a>
            <a href="https://www.amazon.in/Agenda-Movies-Exposing-Hidden-Messages-ebook/dp/B0B57TDLF1/" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon IN
            </a>
            <a href="http://www.amazon.co.jp/dp/B0B49NLK2F" 
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon JP
            </a>
            <a href="https://www.amazon.com.br/Agenda-Movies-Exposing-Messages-English-ebook/dp/B0B57TDLF1/" className="button book-action-button" target="_blank" sx={{
                variant: "variants.button",
              }}
              >Amazon BR
            </a>
            <a href="http://www.amazon.com.mx/dp/B0B49NLK2F"
              className="button book-action-button" 
              target="_blank"
              sx={{
                variant: "variants.button",
              }}
              >Amazon MX
            </a>
          </div>
          <p>Please send any <Link href="/contact" target="_blank">feedback</Link> and write a quick review on Amazon, thank you! To be notified about new book releases, please subscribe to the mailing list below.</p>

          <a href="http://eepurl.com/h2yUfj" 
            className="button" 
            target="_blank"
            sx={{
              variant: "variants.button",
            }}
            >Subscribe
          </a>
        </div>
        <div>
          <div>
            <a href="http://www.amazon.co.uk/dp/B0B49NLK2F" target="_blank">
            {Image ? (
              <GatsbyImage
                image={Image}
                title="The cover is based on a real photo from Hollywood"
                alt={frontmatter.title + " - Featured image"}
                className="featured-image book-image"
              />
            ) : (
              ""
            )}
            </a>
          </div>
          <div>
            <a href="http://www.amazon.co.uk/dp/B0B49NLK2F" target="_blank">
              <img
                src="/assets/Agenda_Movies_nature.png"
                title="Look at this beautiful baby! :o"
                className="featured-image book-image"
              />
            </a>
          </div>  
        </div>
      </div>
    </Layout>
  )
}

export default BooksPage
